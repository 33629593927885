<template>
  <a-modal
    title="详情"
    width='50%'
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @cancel="handleCancel"
    :footer="null"
  >
    <a-spin :spinning="confirmLoading">
      <a-row>
        <a-form layout="inline">
          <a-col>
            <!-- 商品信息 -->
            <a-row>
              <a-col :md="24" :sm="24">
                <a-form-item label="供应商">
                  <span>{{ details.storeName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="商品名称">
                  <span>{{ details.name }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="商品分类">
                  <span>{{ details.goodsCategoryName }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="商品关键字">
                  <span>{{ details.keywords }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="规格类型">
                  <span>{{ details.speType == 1 ? '单规格' : details.speType == 2 ? '多规格' : '' }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="参考价">
                  <span>{{ details.price }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="12" :sm="24">
                <a-form-item label="是否需要开发票">
                  <span>{{ +details.isInvoice === 2 ? '是' : '否' }}</span>
                </a-form-item>
              </a-col>
              <!-- <a-col :md="12" :sm="24">
                                <a-form-item  label="邮费">
                                    <span>{{details.postage}}</span>
                                </a-form-item>
                            </a-col> -->
              <a-col :md="12" :sm="24">
                <a-form-item label="退货政策">
                  <span>{{
                    details.returnGoodsPolicy == 1 ? '七天退货' : details.returnGoodsPolicy == 2 ? '不退货' : ''
                  }}</span>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24">
                <a-form-item label="封面图">
                  <viewer :images="[details.coverImage]">
                    <img :src="details.coverImage" style="border: 0.5px solid #ccc; padding: 5px; margin: 3px" alt="" />
                  </viewer>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" v-if="details.rotationImages">
                <a-form-item label="轮播图">
                  <viewer :images="details.rotationImages.split(',')">
                    <img
                      v-for="(item, index) in details.rotationImages.split(',')"
                      style="border: 0.5px solid #ccc; padding: 5px; margin: 3px"
                      :key="index"
                      :src="item"
                    />
                  </viewer>
                </a-form-item>
              </a-col>
              <a-col :md="24" :sm="24" v-if="details.detail && details.detail.length > 7">
                <a-form-item label="商品详情">
                  <span class="detail" v-html="details.detail"></span>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </a-form>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { commodityDetail } from '@/api/modular/mallLiving/commodity/commodityList'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      indexBigImg: false,
      lunboBigImg: false,
      id: '',
      details: {},
      imagesList: [],
      indexBig: '',
      lunboBig: '',
    }
  },
  methods: {
    //初始化方法
    detail(record) {
      this.visible = true
      this.confirmLoading = true
      //获取详情数据
      setTimeout(() => {
        commodityDetail({ id: record.id }).then((res) => {
          if (res.success) {
            this.details = res.data
            this.confirmLoading = false
          }
        })
      }, 200)
    },
    // 展示大图
    showImg(item) {
      this.showBigImg = true
      this.bigImg = item
    },
    // 点击遮罩层或右上角叉或取消按钮的操作
    handleCancel() {
      this.visible = false
      this.confirmLoading = false
      this.details = {}
      this.imagesList = []
    },
    // 关闭展示大图弹框
    handleCancel_1() {
      this.showBigImg = false
    },
  },
}
</script>
<style lang="less" scoped>
.mytable {
  margin-bottom: 70px;
  border-collapse: collapse;
  width: 100%;
  height: 250px;
  .title {
    background: rgb(207, 248, 248);
    width: 20%;
  }
  .con {
    width: 30%;
  }
  td {
    border: 2px solid rgb(228, 225, 225);
    padding-left: 7px;
    font-size: 15px;
  }
}
img {
  width: 100px;
}
.detail {
  display: block;
  border: 0.5px solid #ccc;
  padding: 0 5px;
  margin-top: 10px;
}
</style>
